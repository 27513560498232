import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Markdown from 'react-remarkable';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Section from "../../components/Section";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import SendMoneyTuition from "../../components/SendMoneyTuition";
import BucketGroupSticky from "../../components/BucketGroupSticky";
import BucketForTwoColumn from "../../components/BucketForSticky";
import SecurityWithImage from "../../components/SecurityWithImage";
import BucketGroup from "../../components/BucketGroup";
import Bucket from "../../components/Bucket";
import Questions from "../../components/Questions";

import iconHedging from "../../img/icon-hedging-purple.svg";
import iconGlobal from "../../img/icon-global-purple.svg";
import iconPayments from "../../img/icon-payments-purple.svg";
import iconFullyHosted from "../../img/icon-fully-hosted.svg";
import iconAdminDash from "../../img/icon-admin-dash.svg";
import iconCustomizable from "../../img/icon-customizable.svg";

import styles from "../../css/pages/tuition-page.module.scss";

import logoVisa from "../../img/logo-visa.png";
import logoMastercard from "../../img/logo-mastercard.png";
import logoApple from "../../img/logo-applepay.png";
import logoUnion from "../../img/logo-union.png";
import logoAlipay from "../../img/logo-alipay.png";
import logoWechat from "../../img/logo-wechat.png";
import logoUpi from "../../img/logo-upi.png";
import logoBoleto from "../../img/logo-boleto.png";

const TuitionPage = ({intl}) => {
  return (
    <Layout>
      <Background themeType={"light"}>
        <Hero
          type={"tuition"}
          backgroundType={"dark"}
          className={styles.heroTuition}
        >
          <MotifColumn>
            <span className={styles.subHeading}>{intl.formatMessage({id: 'tuition.hero.title'})}</span>
            <h1 className={styles.heading}>{intl.formatMessage({id: 'tuition.hero.text'})}</h1>
          </MotifColumn>
          <SendMoneyTuition />
        </Hero>
        <Section className={styles.paymentMethodsSection}>
          <Row>
            <Col xs={12} lg={5}>
            <Markdown>{intl.formatMessage({id: 'tuition.methods'})}</Markdown>
            </Col>
          </Row>
          <Row className={styles.paymentLogoRow}>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoVisa,
                  alt: "Visa Logo",
                  width: 132,
                  height: 60
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoMastercard,
                  alt: "Mastercard Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoApple,
                  alt: "Apple Pay Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoUnion,
                  alt: "Union Pay Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoAlipay,
                  alt: "Alipay Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoWechat,
                  alt: "WeChat Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoUpi,
                  alt: "UPI Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.paymentLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoBoleto,
                  alt: "Boleto Logo"
                }}
              />
            </Col>
          </Row>
        </Section>
        <BucketGroupSticky headingText={intl.formatMessage({id: 'tuition.buckets.title'})}>
          <BucketForTwoColumn
            imageURL={iconHedging}
            title={intl.formatMessage({id: 'tuition.buckets.first.title'})}
            description={intl.formatMessage({id: 'tuition.buckets.first.text'})}
          />
          <BucketForTwoColumn
            imageURL={iconGlobal}
            title={intl.formatMessage({id: 'tuition.buckets.second.title'})}
            description={intl.formatMessage({id: 'tuition.buckets.second.text'})}
          />
          <BucketForTwoColumn
            imageURL={iconPayments}
            title={intl.formatMessage({id: 'tuition.buckets.third.title'})}
            description={intl.formatMessage({id: 'tuition.buckets.third.text'})}
          />
        </BucketGroupSticky>
        <SecurityWithImage />
        <BucketGroup
          headingText={intl.formatMessage({id: 'tuition.schools.title'})}
          subHeadingText={intl.formatMessage({id: 'tuition.schools.text'})}
        >
          <Bucket
            imageURL={iconFullyHosted}
            title={intl.formatMessage({id: 'tuition.schools.first.title'})}
          />
          <Bucket
            imageURL={iconAdminDash}
            title={intl.formatMessage({id: 'tuition.schools.second.title'})}
          />
          <Bucket
            imageURL={iconCustomizable}
            title={intl.formatMessage({id: 'tuition.schools.third.title'})}
          />
        </BucketGroup>
        <Container className={styles.additionalResources}>
          <Row>
            <Col>
              <Markdown>{intl.formatMessage({id: 'tuition.additional'})}</Markdown>
            </Col>
          </Row>
        </Container>
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(TuitionPage);
