import React from "react";
import classnames from "classnames/bind";
import _ from "lodash";
import { Col, Form } from "react-bootstrap";
import { injectIntl } from 'gatsby-plugin-intl';
import Markdown from 'react-remarkable';

import Button from "../Button";

import styles from "./index.module.scss";

let classNamesBound = classnames.bind(styles);

function encode(data) {
	return Object.keys(data)
		.map(
			key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
		)
		.join("&");
}

class SendMoneyTuition extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			school: "",
			name: "",
			invoice: ""
		};
	}

	onSchoolChange(event) {
		this.setState({ school: event.target.value });
		this.updateInvoiceID();
	}

	onNameChange(event) {
		this.setState({ name: event.target.value });
		this.updateInvoiceID();
	}

	updateInvoiceID() {
		// Invoice ID based on school name, student name and timestamp
		this.setState((state, props) => ({
			invoice:
				_.startCase(state.school)
					.replace(/[^A-Z]/g, "")
					.slice(0, 3) +
				_.startCase(state.name)
					.replace(/[^A-Z]/g, "")
					.slice(0, 3) +
				new Date().getTime()
		}));
	}

	handleSubmit(event) {
		event.preventDefault();
		const form = event.target;

		if (!this.state.school || !this.state.name )
			return;

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": form.getAttribute("name"),
				...this.state
			})
		})
			.then(() => form.submit())
			.catch(error => alert(error));
	}

	render() {
		const intl = this.props.intl;

		let formSendMoneyTuitionSelectClasses = classNamesBound({
			sendMoneyTuitionSelect: true
		});

		return (
			<Col xs={12} lg={6} className={styles.sendMoneyTuitionWrapper}>
				<div className={styles.sendMoneyTuition}>
					<Markdown options={{html: true}}>{intl.formatMessage({id: 'send-money-tuition.title'})}</Markdown>
					<Form
						data-netlify="true"
						name="tuition"
						method="GET"
						action={intl.formatMessage({id: 'send-money-tuition.form.action'})}
						onSubmit={event => this.handleSubmit(event)}
					>
						<input type="hidden" name="form-name" value="tuition" />
						<input
							type="hidden"
							name="invoice"
							value={this.state.invoice}
						/>
						<Form.Group controlId="formSchoolName">
							<Form.Label>{intl.formatMessage({id: 'send-money-tuition.form.school-name.label'})}</Form.Label>
							<Form.Control
								required
								as="select"
								name="school"
								className={formSendMoneyTuitionSelectClasses}
								onChange={event => this.onSchoolChange(event)}
							>
								<option value="">Select School</option>
								<option>A.T. Still University</option>
								<option>Abilene Christian University</option>
								<option>
									Abraham Baldwin Agricultural College
								</option>
								<option>Academy of Art University</option>
								<option>Acadia University</option>
								<option>Adams State University</option>
								<option>Adelphi University</option>
								<option>Adler Graduate School</option>
								<option>Adler University</option>
								<option>Adrian College</option>
								<option>
									Adventist University of Health Sciences
								</option>
								<option>Agnes Scott College</option>
								<option>
									Air Force Institute of Technology
								</option>
								<option>Alabama A&amp;M University</option>
								<option>Alabama State University</option>
								<option>Alaska Bible College</option>
								<option>Alaska Pacific University</option>
								<option>
									Albany College of Pharmacy and Health
									Sciences
								</option>
								<option>Albany Law School</option>
								<option>Albany Medical College</option>
								<option>Albany State University</option>
								<option>Albertus Magnus College</option>
								<option>Albion College</option>
								<option>Albright College</option>
								<option>Alcorn State University</option>
								<option>Alderson Broaddus University</option>
								<option>Alfred State College</option>
								<option>Alfred University</option>
								<option>Algoma University</option>
								<option>Alice Lloyd College</option>
								<option>Allegheny College</option>
								<option>Allen University</option>
								<option>
									Alliant International University
								</option>
								<option>Alma College</option>
								<option>Alvernia University</option>
								<option>Alverno College</option>
								<option>Amberton University</option>
								<option>Ambrose University</option>
								<option>American Baptist College</option>
								<option>
									American Film Institute Conservatory
								</option>
								<option>American International College</option>
								<option>American Jewish University</option>
								<option>American University</option>
								<option>Amherst College</option>
								<option>Anderson University</option>
								<option>Anderson University</option>
								<option>Andrews University</option>
								<option>Angelo State University</option>
								<option>Anna Maria College</option>
								<option>Antioch University</option>
								<option>Antioch University Los Angeles</option>
								<option>Antioch University New England</option>
								<option>
									Antioch University Santa Barbara
								</option>
								<option>Antioch University Seattle</option>
								<option>Appalachian Bible College</option>
								<option>Appalachian College of Pharmacy</option>
								<option>Appalachian State University</option>
								<option>Aquinas College</option>
								<option>Aquinas College</option>
								<option>Arcadia University</option>
								<option>Argosy University</option>
								<option>Arizona Christian University</option>
								<option>Arizona State University</option>
								<option>Arkansas Baptist College</option>
								<option>Arkansas State University</option>
								<option>Arkansas Tech University</option>
								<option>Arlington Baptist University</option>
								<option>Art Academy of Cincinnati</option>
								<option>ArtCenter College of Design</option>
								<option>Asbury University</option>
								<option>Ashland University</option>
								<option>Assumption College</option>
								<option>Athens State University</option>
								<option>
									Atlanta Metropolitan State College
								</option>
								<option>Auburn University</option>
								<option>Auburn University at Montgomery</option>
								<option>Augsburg College</option>
								<option>Augusta University</option>
								<option>Augustana College</option>
								<option>Augustana University</option>
								<option>Aurora College</option>
								<option>Aurora University</option>
								<option>Austin College</option>
								<option>Austin Peay State University</option>
								<option>Ave Maria University</option>
								<option>Averett University</option>
								<option>Avila University</option>
								<option>Azusa Pacific University</option>
								<option>Babson College</option>
								<option>Bacone College</option>
								<option>Baker College</option>
								<option>Baker University</option>
								<option>Baldwin Wallace University</option>
								<option>Ball State University</option>
								<option>
									Bank Street College of Education
								</option>
								<option>Baptist Bible College</option>
								<option>
									Baptist University of the Americas
								</option>
								<option>Barclay College</option>
								<option>Bard College</option>
								<option>Bard College at Simon's Rock</option>
								<option>Barnard College</option>
								<option>
									Barnes-Jewish College Goldfarb School of
									Nursing
								</option>
								<option>Barry University</option>
								<option>Barton College</option>
								<option>Bastyr University</option>
								<option>Bates College</option>
								<option>Bay Path University</option>
								<option>Bay State College</option>
								<option>Baylor College of Medicine</option>
								<option>Baylor University</option>
								<option>Beacon College</option>
								<option>Becker College</option>
								<option>Belhaven University</option>
								<option>Bellarmine University</option>
								<option>Bellevue College</option>
								<option>Bellevue University</option>
								<option>Bellin College</option>
								<option>Belmont Abbey College</option>
								<option>Belmont University</option>
								<option>Beloit College</option>
								<option>Bemidji State University</option>
								<option>Benedict College</option>
								<option>Benedictine College</option>
								<option>Benedictine University</option>
								<option>
									Benjamin Franklin Institute of Technology
								</option>
								<option>Bennett College</option>
								<option>Bennington College</option>
								<option>Bentley University</option>
								<option>Berea College</option>
								<option>Berkeley College</option>
								<option>Berklee College of Music</option>
								<option>Bernard M Baruch College</option>
								<option>Berry College</option>
								<option>Bethany College</option>
								<option>Bethany College</option>
								<option>Bethany Lutheran College</option>
								<option>Bethel College</option>
								<option>Bethel College-Indiana</option>
								<option>Bethel University</option>
								<option>Bethel University</option>
								<option>Bethesda University</option>
								<option>Bethune-Cookman University</option>
								<option>Beulah Heights University</option>
								<option>Binghamton University</option>
								<option>Biola University</option>
								<option>Birmingham-Southern College</option>
								<option>Bishop's University</option>
								<option>Bismarck State College</option>
								<option>Black Hills State University</option>
								<option>Blackburn College</option>
								<option>
									Blessing-Rieman College of Nursing
								</option>
								<option>Bloomfield College</option>
								<option>
									Bloomsburg University of Pennsylvania
								</option>
								<option>Blue Mountain College</option>
								<option>Bluefield College</option>
								<option>Bluefield State College</option>
								<option>Bluffton University</option>
								<option>Bob Jones University</option>
								<option>Boise Bible College</option>
								<option>Boise State University</option>
								<option>Boricua College</option>
								<option>Boston Architectural College</option>
								<option>Boston College</option>
								<option>
									Boston Graduate School of Psychoanalysis
								</option>
								<option>Boston University</option>
								<option>Bowdoin College</option>
								<option>Bowling Green State University</option>
								<option>Bradley University</option>
								<option>Brandeis University</option>
								<option>Brandman University</option>
								<option>Brandon University</option>
								<option>Brazosport College</option>
								<option>Brenau University</option>
								<option>Brescia University</option>
								<option>Brevard College</option>
								<option>Brewton-Parker College</option>
								<option>Briar Cliff University</option>
								<option>Briarcliffe College</option>
								<option>Bridgewater College</option>
								<option>Bridgewater State University</option>
								<option>Brigham Young University</option>
								<option>Brigham Young University-Hawaii</option>
								<option>Brigham Young University-Idaho</option>
								<option>
									British Columbia Institute of Technology
								</option>
								<option>Brock University</option>
								<option>Brooklyn College</option>
								<option>Brooklyn Law School</option>
								<option>Broward College</option>
								<option>Brown Mackie College</option>
								<option>Brown University</option>
								<option>Bryan College</option>
								<option>
									Bryan College of Health Sciences
								</option>
								<option>Bryant University</option>
								<option>Bryant and Stratton College</option>
								<option>Bryn Athyn College</option>
								<option>Bryn Mawr College</option>
								<option>Bucknell University</option>
								<option>Buena Vista University</option>
								<option>Buffalo State College</option>
								<option>Burman University</option>
								<option>Butler University</option>
								<option>CUNY School of Law</option>
								<option>
									Cabarrus College of Health Sciences
								</option>
								<option>Cabrini University</option>
								<option>Cairn University</option>
								<option>Caldwell University</option>
								<option>California Baptist University</option>
								<option>California College of the Arts</option>
								<option>
									California Institute of Integral Studies
								</option>
								<option>
									California Institute of Technology
								</option>
								<option>
									California Institute of the Arts
								</option>
								<option>California Lutheran University</option>
								<option>California Polytechnic State</option>
								<option>
									California Polytechnic State, Pomona
								</option>
								<option>
									California State University Channel Islands
								</option>
								<option>
									California State University San Marcos
								</option>
								<option>
									California State University Stanislaus
								</option>
								<option>
									California State University, Bakersfield
								</option>
								<option>
									California State University, California
									State University
								</option>
								<option>
									California State University, Dominguez Hills
								</option>
								<option>
									California State University, East Bay
								</option>
								<option>
									California State University, Fresno
								</option>
								<option>
									California State University, Fullerton
								</option>
								<option>California State University, LA</option>
								<option>
									California State University, Long Beach
								</option>
								<option>
									California State University, Maritime
									Academy
								</option>
								<option>
									California State University, Monteray Bay
								</option>
								<option>
									California State University, Northridge
								</option>
								<option>
									California State University, Sacremento
								</option>
								<option>
									California State University, San Bernardino
								</option>
								<option>
									California University of Pennsylvania
								</option>
								<option>
									California Western School of Law
								</option>
								<option>Calumet College of St. Joseph</option>
								<option>Calvary University</option>
								<option>Calvin College</option>
								<option>Cambridge College</option>
								<option>Cameron University</option>
								<option>Campbell University</option>
								<option>Campbellsville University</option>
								<option>
									Campion College at the University of Regina
								</option>
								<option>Canadian Mennonite University</option>
								<option>Canisius College</option>
								<option>Cape Breton University</option>
								<option>Capilano University</option>
								<option>Capital University</option>
								<option>Capitol Technology University</option>
								<option>Cardinal Stritch University</option>
								<option>Carleton College</option>
								<option>Carleton University</option>
								<option>Carlow University</option>
								<option>Carnegie Mellon University</option>
								<option>Carroll College</option>
								<option>Carroll University</option>
								<option>Carson-Newman University</option>
								<option>Carthage College</option>
								<option>Case Western Reserve University</option>
								<option>Castleton University</option>
								<option>Catawba College</option>
								<option>Catholic Theological Union</option>
								<option>Cazenovia College</option>
								<option>Cedar Crest College</option>
								<option>Cedarville University</option>
								<option>Centenary College of Louisiana</option>
								<option>Centenary University</option>
								<option>Central Baptist College</option>
								<option>
									Central Christian College of Kansas
								</option>
								<option>
									Central Christian College of the Bible
								</option>
								<option>Central College</option>
								<option>
									Central Connecticut State University
								</option>
								<option>Central Methodist University</option>
								<option>Central Michigan University</option>
								<option>Central Penn College</option>
								<option>Central State University</option>
								<option>Central Washington University</option>
								<option>Centralia College</option>
								<option>Centre College</option>
								<option>Chadron State College</option>
								<option>Chamberlain University</option>
								<option>
									Chaminade University of Honolulu
								</option>
								<option>Champlain College</option>
								<option>Chapman University</option>
								<option>
									Charles R. Drew University of Medicine and
									Science
								</option>
								<option>Charleston Southern University</option>
								<option>Chatham University</option>
								<option>Chestnut Hill College</option>
								<option>
									Cheyney University of Pennsylvania
								</option>
								<option>Chicago State University</option>
								<option>Chipola College</option>
								<option>Chowan University</option>
								<option>Christian Brothers University</option>
								<option>Christopher Newport University</option>
								<option>Cincinnati Christian University</option>
								<option>City University of Seattle</option>
								<option>Claflin University</option>
								<option>Claremont Graduate University</option>
								<option>Claremont McKenna College</option>
								<option>
									Clarion University of Pennsylvania
								</option>
								<option>Clark Atlanta University</option>
								<option>Clark University</option>
								<option>Clarke University</option>
								<option>Clarks Summit University</option>
								<option>Clarkson College</option>
								<option>Clarkson University</option>
								<option>Clayton State University</option>
								<option>
									Clear Creek Baptist Bible College
								</option>
								<option>Cleary University</option>
								<option>Clemson University</option>
								<option>Cleveland Institute of Art</option>
								<option>Cleveland Institute of Music</option>
								<option>Cleveland State University</option>
								<option>
									Cleveland University-Kansas City
								</option>
								<option>Coastal Carolina University</option>
								<option>Coe College</option>
								<option>Cogswell Polytechnical College</option>
								<option>Coker College</option>
								<option>Colby College</option>
								<option>Colby-Sawyer College</option>
								<option>Colgate University</option>
								<option>College for Creative Studies</option>
								<option>College of Biblical Studies</option>
								<option>College of Central Florida</option>
								<option>College of Charleston</option>
								<option>College of Coastal Georgia</option>
								<option>College of Mount Saint Vincent</option>
								<option>College of Our Lady of the Elms</option>
								<option>
									College of Saint Benedict/Saint John's
									University
								</option>
								<option>College of Saint Elizabeth</option>
								<option>College of Saint Mary</option>
								<option>College of Southern Nevada</option>
								<option>College of St. Joseph</option>
								<option>College of Staten Island</option>
								<option>College of William &amp; Mary</option>
								<option>College of the Atlantic</option>
								<option>College of the Holy Cross</option>
								<option>College of the North Atlantic</option>
								<option>College of the Ozarks</option>
								<option>
									Collège Universitaire Dominicain
								</option>
								<option>
									Collège militaire royal du Canada
								</option>
								<option>Colorado Christian University</option>
								<option>Colorado College</option>
								<option>Colorado Mesa University</option>
								<option>Colorado School of Mines</option>
								<option>Colorado State University</option>
								<option>
									Colorado State University-Pueblo
								</option>
								<option>Colorado Technical University</option>
								<option>Columbia Basin College</option>
								<option>Columbia College</option>
								<option>Columbia College</option>
								<option>Columbia College Chicago</option>
								<option>Columbia College of Nursing</option>
								<option>Columbia College-Hollywood</option>
								<option>
									Columbia International University
								</option>
								<option>
									Columbia University in the City of New York
								</option>
								<option>
									Columbus College of Art and Design
								</option>
								<option>Columbus State University</option>
								<option>Concord University</option>
								<option>Concordia College</option>
								<option>Concordia College Alabama</option>
								<option>Concordia College-New York</option>
								<option>Concordia University</option>
								<option>Concordia University Ann Arbor</option>
								<option>Concordia University Chicago</option>
								<option>Concordia University Irvine</option>
								<option>Concordia University Portland</option>
								<option>Concordia University Texas</option>
								<option>Concordia University Wisconsin</option>
								<option>
									Concordia University of Edmonton
								</option>
								<option>Concordia University, St Paul</option>
								<option>Connecticut College</option>
								<option>Converse College</option>
								<option>
									Conway School of Landscape Design
								</option>
								<option>Coppin State University</option>
								<option>Corban University</option>
								<option>Cornell College</option>
								<option>Cornell University</option>
								<option>Cornerstone University</option>
								<option>Cornish College of the Arts</option>
								<option>Cottey College</option>
								<option>Covenant College</option>
								<option>Cox College</option>
								<option>Cranbrook Academy of Art</option>
								<option>Crandall University</option>
								<option>Creighton University</option>
								<option>Criswell College</option>
								<option>Crossroads Bible College</option>
								<option>Crossroads College</option>
								<option>Crowley's Ridge College</option>
								<option>Crown College</option>
								<option>Culver-Stockton College</option>
								<option>Cumberland University</option>
								<option>Curry College</option>
								<option>Curtis Institute of Music</option>
								<option>D'Youville College</option>
								<option>Daemen College</option>
								<option>Dakota State University</option>
								<option>Dakota Wesleyan University</option>
								<option>Dalhousie University</option>
								<option>Dallas Baptist University</option>
								<option>Dallas Christian College</option>
								<option>Dalton State College</option>
								<option>Dartmouth College</option>
								<option>Davenport University</option>
								<option>Davidson College</option>
								<option>Davis &amp; Elkins College</option>
								<option>Davis College</option>
								<option>Daytona State College</option>
								<option>DePaul University</option>
								<option>DePauw University</option>
								<option>DeSales University</option>
								<option>DeVry University</option>
								<option>Dean College</option>
								<option>Defiance College</option>
								<option>Delaware State University</option>
								<option>Delaware Valley University</option>
								<option>Delta State University</option>
								<option>Denison University</option>
								<option>Denver College of Nursing</option>
								<option>Des Moines University</option>
								<option>Dickinson College</option>
								<option>Dickinson State University</option>
								<option>Dillard University</option>
								<option>Dixie State University</option>
								<option>Doane University</option>
								<option>Dominican College</option>
								<option>
									Dominican School of Philosophy &amp;
									Theology
								</option>
								<option>Dominican University</option>
								<option>
									Dominican University of California
								</option>
								<option>Donnelly College</option>
								<option>Dordt College</option>
								<option>Drake University</option>
								<option>Drew University</option>
								<option>Drexel University</option>
								<option>Drury University</option>
								<option>Duke University</option>
								<option>Dunwoody College of Technology</option>
								<option>Duquesne University</option>
								<option>ECPI University</option>
								<option>Earlham College</option>
								<option>East Carolina University</option>
								<option>East Central University</option>
								<option>East Georgia State College</option>
								<option>East Stroudsburg University</option>
								<option>East Tennessee State University</option>
								<option>East Texas Baptist University</option>
								<option>East-West University</option>
								<option>
									Eastern Connecticut State University
								</option>
								<option>Eastern Illinois University</option>
								<option>Eastern Kentucky University</option>
								<option>Eastern Mennonite University</option>
								<option>Eastern Michigan University</option>
								<option>Eastern Nazarene College</option>
								<option>Eastern New Mexico University</option>
								<option>Eastern Oregon University</option>
								<option>Eastern University</option>
								<option>Eastern Virginia Medical School</option>
								<option>Eastern Washington University</option>
								<option>Ecclesia College</option>
								<option>Eckerd College</option>
								<option>Edgewood College</option>
								<option>
									Edinboro University of Pennsylvania
								</option>
								<option>Edward Waters College</option>
								<option>Elizabeth City State University</option>
								<option>Elizabethtown College</option>
								<option>Elmhurst College</option>
								<option>Elmira College</option>
								<option>Elon University</option>
								<option>
									Embry-Riddle Aeronautical University
								</option>
								<option>Emerson College</option>
								<option>
									Emily Carr University of Art and Design
								</option>
								<option>Emmanuel College</option>
								<option>Emmanuel College</option>
								<option>Emmaus Bible College</option>
								<option>Emory &amp; Henry College</option>
								<option>Emory University</option>
								<option>Emporia State University</option>
								<option>Endicott College</option>
								<option>Erskine College</option>
								<option>Eureka College</option>
								<option>Evangel University</option>
								<option>Everglades University</option>
								<option>Fairfield University</option>
								<option>Fairleigh Dickinson University</option>
								<option>Fairmont State University</option>
								<option>
									Faith Baptist Bible College and Theological
									Seminary
								</option>
								<option>Farmingdale State College</option>
								<option>Fashion Institute of Technology</option>
								<option>Faulkner University</option>
								<option>Fayetteville State University</option>
								<option>Felician University</option>
								<option>Ferris State University</option>
								<option>Ferrum College</option>
								<option>Fielding Graduate University</option>
								<option>Finlandia University</option>
								<option>
									First Nations University of Canada
								</option>
								<option>Fisher College</option>
								<option>Fisk University</option>
								<option>Fitchburg State University</option>
								<option>Five Towns College</option>
								<option>Flagler College</option>
								<option>
									Florida Agricultural and Mechanical
									University
								</option>
								<option>Florida Atlantic University</option>
								<option>Florida College</option>
								<option>Florida Gateway College</option>
								<option>Florida Gulf Coast University</option>
								<option>Florida Institute of Technology</option>
								<option>
									Florida International University
								</option>
								<option>Florida Memorial University</option>
								<option>Florida National University</option>
								<option>
									Florida SouthWestern State College
								</option>
								<option>Florida Southern College</option>
								<option>
									Florida State College at Jacksonville
								</option>
								<option>Florida State University</option>
								<option>Fontbonne University</option>
								<option>Fordham University</option>
								<option>Fort Hays State University</option>
								<option>Fort Lewis College</option>
								<option>Fort Valley State University</option>
								<option>Framingham State University</option>
								<option>Francis Marion University</option>
								<option>
									Franciscan Missionaries of Our Lady
									University
								</option>
								<option>
									Franciscan University of Steubenville
								</option>
								<option>Franklin College</option>
								<option>Franklin Pierce University</option>
								<option>Franklin University</option>
								<option>
									Franklin W. Olin College of Engineering
								</option>
								<option>Franklin and Marshall College</option>
								<option>Freed-Hardeman University</option>
								<option>Fresno Pacific University</option>
								<option>Friends University</option>
								<option>Frostburg State University</option>
								<option>Furman University</option>
								<option>Gallaudet University</option>
								<option>Gannon University</option>
								<option>Gardner-Webb University</option>
								<option>
									Geisinger Commonwealth School of Medicine
								</option>
								<option>Geneva College</option>
								<option>George Fox University</option>
								<option>George Mason University</option>
								<option>George Washington University</option>
								<option>Georgetown College</option>
								<option>Georgetown University</option>
								<option>
									Georgia College and State University
								</option>
								<option>Georgia Gwinnett College</option>
								<option>Georgia Institute of Technology</option>
								<option>Georgia Southern University</option>
								<option>
									Georgia Southwestern State University
								</option>
								<option>Georgia State University</option>
								<option>Georgian Court University</option>
								<option>Gettysburg College</option>
								<option>Glenville State College</option>
								<option>God's Bible School and College</option>
								<option>Goddard College</option>
								<option>Golden Gate University</option>
								<option>Goldey-Beacom College</option>
								<option>Gonzaga University</option>
								<option>
									Good Samaritan College of Nursing and Health
									Science
								</option>
								<option>Goodwin College</option>
								<option>Gordon College</option>
								<option>Gordon State College</option>
								<option>Goshen College</option>
								<option>Goucher College</option>
								<option>Governors State University</option>
								<option>Grace Christian University</option>
								<option>Grace College</option>
								<option>Grace University</option>
								<option>Graceland University</option>
								<option>Grambling State University</option>
								<option>Grand Canyon University</option>
								<option>Grand Valley State University</option>
								<option>Grand View University</option>
								<option>Granite State College</option>
								<option>Gratz College</option>
								<option>Great Basin College</option>
								<option>Great Lakes Christian College</option>
								<option>Green Mountain College</option>
								<option>Greensboro College</option>
								<option>Greenville University</option>
								<option>Grinnell College</option>
								<option>Grove City College</option>
								<option>Guilford College</option>
								<option>Gulf Coast State College</option>
								<option>Gustavus Adolphus College</option>
								<option>Gwynedd Mercy University</option>
								<option>HEC Montréal</option>
								<option>Hamilton College</option>
								<option>Hamline University</option>
								<option>Hampden-Sydney College</option>
								<option>Hampshire College</option>
								<option>Hampton University</option>
								<option>Hannibal-LaGrange University</option>
								<option>Hanover College</option>
								<option>Hardin-Simmons University</option>
								<option>Harding University</option>
								<option>Harris-Stowe State University</option>
								<option>
									Harrisburg University of Science and
									Technology
								</option>
								<option>Hartwick College</option>
								<option>Harvard University</option>
								<option>Harvey Mudd College</option>
								<option>Hastings College</option>
								<option>Haverford College</option>
								<option>Hawaii Pacific University</option>
								<option>Hebrew College</option>
								<option>Heidelberg University</option>
								<option>Helene Fuld College of Nursing</option>
								<option>Henderson State University</option>
								<option>Hendrix College</option>
								<option>Heritage Christian University</option>
								<option>Heritage University</option>
								<option>Herzing University</option>
								<option>High Point University</option>
								<option>Hilbert College</option>
								<option>Hillsdale College</option>
								<option>Hiram College</option>
								<option>
									Hobart and William Smith Colleges
								</option>
								<option>Hodges University</option>
								<option>Hofstra University</option>
								<option>Hollins University</option>
								<option>
									Holy Apostles College and Seminary
								</option>
								<option>Holy Cross College</option>
								<option>Holy Family University</option>
								<option>Holy Names University</option>
								<option>Hood College</option>
								<option>Hope College</option>
								<option>Hope International University</option>
								<option>Houghton College</option>
								<option>Houston Baptist University</option>
								<option>Howard Payne University</option>
								<option>Howard University</option>
								<option>
									Hult International Business School
								</option>
								<option>Humboldt State University</option>
								<option>Humphreys University</option>
								<option>Hunter College</option>
								<option>Huntingdon College</option>
								<option>Huntington University</option>
								<option>Husson University</option>
								<option>Huston-Tillotson University</option>
								<option>
									Icahn School of Medicine at Mount Sinai
								</option>
								<option>Idaho State University</option>
								<option>Illinois College</option>
								<option>Illinois College of Optometry</option>
								<option>
									Illinois Institute of Technology
								</option>
								<option>Illinois State University</option>
								<option>Illinois Wesleyan University</option>
								<option>Immaculata University</option>
								<option>Indian River State College</option>
								<option>Indiana Institute of Technology</option>
								<option>Indiana State University</option>
								<option>
									Indiana University - Purdue University
									Indianapolis
								</option>
								<option>Indiana University Bloomington</option>
								<option>Indiana University East</option>
								<option>Indiana University Fort Wayne</option>
								<option>Indiana University Kokomo</option>
								<option>Indiana University Northwest</option>
								<option>Indiana University South Bend</option>
								<option>Indiana University Southeast</option>
								<option>
									Indiana University of Pennsylvania
								</option>
								<option>Indiana Wesleyan University</option>
								<option>Iona College</option>
								<option>
									Iowa State University of Science and
									Technology
								</option>
								<option>Iowa Wesleyan University</option>
								<option>
									Irell and Manella Graduate School of
									Biological Sciences
								</option>
								<option>Ithaca College</option>
								<option>Jackson State University</option>
								<option>Jacksonville State University</option>
								<option>Jacksonville University</option>
								<option>James Madison University</option>
								<option>Jarvis Christian College</option>
								<option>
									Jefferson College of Health Sciences
								</option>
								<option>John Brown University</option>
								<option>John Carroll University</option>
								<option>John F. Kennedy University</option>
								<option>
									John Jay College of Criminal Justice
								</option>
								<option>
									John Paul the Great Catholic University
								</option>
								<option>John Wesley University</option>
								<option>Johns Hopkins University</option>
								<option>Johnson C. Smith University</option>
								<option>Johnson University</option>
								<option>Johnson and Wales University</option>
								<option>Judson College</option>
								<option>Judson University</option>
								<option>Juniata College</option>
								<option>Kalamazoo College</option>
								<option>Kansas City Art Institute</option>
								<option>
									Kansas City University of Medicine and
									Biosciences
								</option>
								<option>Kansas State University</option>
								<option>Kansas Wesleyan University</option>
								<option>Kean University</option>
								<option>Keck Graduate Institute</option>
								<option>Keene State College</option>
								<option>Keiser University</option>
								<option>Kendall College</option>
								<option>Kennesaw State University</option>
								<option>Kent State University</option>
								<option>Kentucky Christian University</option>
								<option>Kentucky State University</option>
								<option>Kentucky Wesleyan College</option>
								<option>Kenyon College</option>
								<option>Kettering College</option>
								<option>Kettering University</option>
								<option>Keuka College</option>
								<option>Keystone College</option>
								<option>King University</option>
								<option>King's College</option>
								<option>Kingswood University</option>
								<option>Knox College</option>
								<option>
									Kutztown University of Pennsylvania
								</option>
								<option>Kuyper College</option>
								<option>Kwantlen Polytechnic University</option>
								<option>LIM College</option>
								<option>La Roche College</option>
								<option>La Salle University</option>
								<option>La Sierra University</option>
								<option>LaGrange College</option>
								<option>Labouré College</option>
								<option>Lafayette College</option>
								<option>
									Laguna College of Art and Design
								</option>
								<option>Lake Erie College</option>
								<option>Lake Forest College</option>
								<option>
									Lake Forest Graduate School of Management
								</option>
								<option>Lake Superior State University</option>
								<option>
									Lake Washington Institute of Technology
								</option>
								<option>Lakehead University</option>
								<option>Lakeland College</option>
								<option>Lakeview College of Nursing</option>
								<option>Lamar University</option>
								<option>Lancaster Bible College</option>
								<option>Lander University</option>
								<option>Landmark College</option>
								<option>Lane College</option>
								<option>Langston University</option>
								<option>Lasell College</option>
								<option>Laurentian University</option>
								<option>
									Lawrence Technological University
								</option>
								<option>Lawrence University</option>
								<option>Le Moyne College</option>
								<option>LeTourneau University</option>
								<option>Lebanon Valley College</option>
								<option>Lee University</option>
								<option>Lees-McRae College</option>
								<option>Lehigh University</option>
								<option>Lenoir-Rhyne University</option>
								<option>Lesley University</option>
								<option>Lewis &amp; Clark College</option>
								<option>Lewis University</option>
								<option>Lewis-Clark State College</option>
								<option>Liberty University</option>
								<option>Life Pacific College</option>
								<option>Life University</option>
								<option>Limestone College</option>
								<option>Lincoln Christian University</option>
								<option>Lincoln College</option>
								<option>Lincoln College of New England</option>
								<option>Lincoln Memorial University</option>
								<option>Lincoln University</option>
								<option>Lincoln University</option>
								<option>Lincoln University</option>
								<option>Lindenwood University</option>
								<option>Lindsey Wilson College</option>
								<option>Linfield College</option>
								<option>Lipscomb University</option>
								<option>Livingstone College</option>
								<option>Lock Haven University</option>
								<option>Logan University</option>
								<option>Loma Linda University</option>
								<option>London School of Economics</option>
								<option>Long Island University</option>
								<option>Longwood University</option>
								<option>Loras College</option>
								<option>Louisiana College</option>
								<option>Louisiana State University</option>
								<option>
									Louisiana State University Health Sciences
									Center
								</option>
								<option>
									Louisiana State University Health Sciences
									Center-Shreveport
								</option>
								<option>
									Louisiana State University in Shreveport
								</option>
								<option>
									Louisiana State University of Alexandria
								</option>
								<option>Louisiana Tech University</option>
								<option>Lourdes University</option>
								<option>Loyola Marymount University</option>
								<option>Loyola University Chicago</option>
								<option>Loyola University Maryland</option>
								<option>Loyola University New Orleans</option>
								<option>Lubbock Christian University</option>
								<option>Luther College</option>
								<option>
									Luther College at the University of Regina
								</option>
								<option>Lycoming College</option>
								<option>Lynchburg College</option>
								<option>Lynn University</option>
								<option>Lyon College</option>
								<option>MCPHS University</option>
								<option>
									MGH Institute of Health Professions
								</option>
								<option>MacEwan University</option>
								<option>MacMurray College</option>
								<option>Macalester College</option>
								<option>Madonna University</option>
								<option>
									Maharishi University of Management
								</option>
								<option>Maine College of Art</option>
								<option>Maine Maritime Academy</option>
								<option>Malone University</option>
								<option>Manchester University</option>
								<option>Manhattan Christian College</option>
								<option>Manhattan College</option>
								<option>Manhattan School of Music</option>
								<option>Manhattanville College</option>
								<option>
									Mansfield University of Pennsylvania
								</option>
								<option>Maranatha Baptist University</option>
								<option>Maria College</option>
								<option>Marian University</option>
								<option>Marian University</option>
								<option>Marietta College</option>
								<option>Marist College</option>
								<option>Marlboro College</option>
								<option>Marquette University</option>
								<option>Mars Hill University</option>
								<option>Marshall B. Ketchum University</option>
								<option>Marshall University</option>
								<option>Martin Luther College</option>
								<option>Martin Methodist College</option>
								<option>Martin University</option>
								<option>Mary Baldwin University</option>
								<option>Marygrove College</option>
								<option>
									Maryland Institute College of Art
								</option>
								<option>
									Maryland University of Integrative Health
								</option>
								<option>Marylhurst University</option>
								<option>Marymount California University</option>
								<option>Marymount Manhattan College</option>
								<option>Marymount University</option>
								<option>Maryville College</option>
								<option>Maryville University</option>
								<option>Marywood University</option>
								<option>
									Massachusetts College of Art and Design
								</option>
								<option>
									Massachusetts College of Liberal Arts
								</option>
								<option>
									Massachusetts Institute of Technology
								</option>
								<option>Massachusetts Maritime Academy</option>
								<option>Massachusetts School of Law</option>
								<option>Mayo Medical School</option>
								<option>Mayville State University</option>
								<option>McDaniel College</option>
								<option>McGill University</option>
								<option>McKendree University</option>
								<option>McMaster University</option>
								<option>McMurry University</option>
								<option>McNeese State University</option>
								<option>McPherson College</option>
								<option>Medaille College</option>
								<option>Medgar Evers College</option>
								<option>Medical College of Wisconsin</option>
								<option>
									Medical University of South Carolina
								</option>
								<option>Meharry Medical College</option>
								<option>
									Memorial University of Newfoundland
								</option>
								<option>Memphis College of Art</option>
								<option>Menlo College</option>
								<option>Mercer University</option>
								<option>Mercy College</option>
								<option>
									Mercy College of Health Sciences
								</option>
								<option>Mercy College of Ohio</option>
								<option>Mercyhurst University</option>
								<option>Meredith College</option>
								<option>Merrimack College</option>
								<option>Messiah College</option>
								<option>Methodist College</option>
								<option>Methodist University</option>
								<option>
									Metropolitan College of New York
								</option>
								<option>Metropolitan State University</option>
								<option>
									Metropolitan State University of Denver
								</option>
								<option>Miami Dade College</option>
								<option>Miami University</option>
								<option>
									Michigan School of Professional Psychology
								</option>
								<option>Michigan State University</option>
								<option>
									Michigan Technological University
								</option>
								<option>
									Mid-America Christian University
								</option>
								<option>
									Mid-Atlantic Christian University
								</option>
								<option>MidAmerica Nazarene University</option>
								<option>Middle Georgia State University</option>
								<option>
									Middle Tennessee School of Anesthesia
								</option>
								<option>
									Middle Tennessee State University
								</option>
								<option>Middlebury College</option>
								<option>Midland College</option>
								<option>Midland University</option>
								<option>Midstate College</option>
								<option>Midway University</option>
								<option>Midwestern State University</option>
								<option>Midwestern University</option>
								<option>Miles College</option>
								<option>
									Millersville University of Pennsylvania
								</option>
								<option>Milligan College</option>
								<option>Millikin University</option>
								<option>Mills College</option>
								<option>Millsaps College</option>
								<option>
									Milwaukee Institute of Art &amp; Design
								</option>
								<option>Milwaukee School of Engineering</option>
								<option>
									Minneapolis College of Art and Design
								</option>
								<option>Minnesota State University</option>
								<option>
									Minnesota State University Moorhead
								</option>
								<option>Minot State University</option>
								<option>Misericordia University</option>
								<option>Mississippi College</option>
								<option>Mississippi State University</option>
								<option>
									Mississippi University for Women
								</option>
								<option>
									Mississippi Valley State University
								</option>
								<option>Missouri Baptist University</option>
								<option>
									Missouri Southern State University
								</option>
								<option>Missouri State University</option>
								<option>
									Missouri University of Science and
									Technology
								</option>
								<option>Missouri Valley College</option>
								<option>
									Missouri Western State University
								</option>
								<option>Mitchell College</option>
								<option>Mitchell Hamline School of Law</option>
								<option>Molloy College</option>
								<option>Monmouth College</option>
								<option>Monmouth University</option>
								<option>Monroe College</option>
								<option>Montana State University</option>
								<option>
									Montana State University Billings
								</option>
								<option>
									Montana State University-Northern
								</option>
								<option>Montana Tech</option>
								<option>Montclair State University</option>
								<option>Montreat College</option>
								<option>Montserrat College of Art</option>
								<option>Moody Bible Institute</option>
								<option>Moore College of Art and Design</option>
								<option>Moravian College</option>
								<option>Morehead State University</option>
								<option>Morehouse College</option>
								<option>Morehouse School of Medicine</option>
								<option>Morgan State University</option>
								<option>Morningside College</option>
								<option>Morris College</option>
								<option>Morrisville State College</option>
								<option>Mount Allison University</option>
								<option>Mount Aloysius College</option>
								<option>Mount Carmel College of Nursing</option>
								<option>Mount Holyoke College</option>
								<option>Mount Ida College</option>
								<option>Mount Marty College</option>
								<option>Mount Mary University</option>
								<option>Mount Mercy University</option>
								<option>Mount Royal University</option>
								<option>Mount Saint Mary College</option>
								<option>Mount Saint Mary's University</option>
								<option>Mount Saint Vincent University</option>
								<option>Mount St. Joseph University</option>
								<option>Mount St. Mary's University</option>
								<option>
									Mount Vernon Nazarene University
								</option>
								<option>Muhlenberg College</option>
								<option>Multnomah University</option>
								<option>Murray State University</option>
								<option>Muskingum University</option>
								<option>NSCAD University</option>
								<option>Naropa University</option>
								<option>National American University</option>
								<option>National Defense University</option>
								<option>National Louis University</option>
								<option>National University</option>
								<option>
									National University of Health Sciences
								</option>
								<option>Naval Postgraduate School</option>
								<option>Nazarene Bible College</option>
								<option>Nazareth College</option>
								<option>Nebraska Christian College</option>
								<option>Nebraska Methodist College</option>
								<option>Nebraska Wesleyan University</option>
								<option>Neumann University</option>
								<option>Neumont University</option>
								<option>Nevada State College</option>
								<option>New College of Florida</option>
								<option>New England College</option>
								<option>
									New England College of Optometry
								</option>
								<option>New England Conservatory</option>
								<option>
									New England Institute of Technology
								</option>
								<option>New England Law | Boston</option>
								<option>New Hope Christian College</option>
								<option>New Jersey City University</option>
								<option>
									New Jersey Institute of Technology
								</option>
								<option>New Mexico Highlands University</option>
								<option>
									New Mexico Institute of Mining and
									Technology
								</option>
								<option>New Mexico State University</option>
								<option>New York Academy of Art</option>
								<option>
									New York City College of Technology
								</option>
								<option>
									New York College of Podiatric Medicine
								</option>
								<option>
									New York Institute of Technology
								</option>
								<option>New York Law School</option>
								<option>New York Medical College</option>
								<option>
									New York School of Interior Design
								</option>
								<option>New York University</option>
								<option>
									NewSchool of Architecture and Design
								</option>
								<option>Newberry College</option>
								<option>Newbury College</option>
								<option>Newman University</option>
								<option>Niagara University</option>
								<option>Nicholls State University</option>
								<option>Nichols College</option>
								<option>
									Nicola Valley Institute of Technology
								</option>
								<option>Nipissing University</option>
								<option>Norfolk State University</option>
								<option>
									North Carolina Agricultural and Technical
									State University
								</option>
								<option>
									North Carolina Central University
								</option>
								<option>North Carolina State University</option>
								<option>North Carolina Wesleyan College</option>
								<option>North Central College</option>
								<option>North Central University</option>
								<option>North Dakota State University</option>
								<option>North Greenville University</option>
								<option>North Park University</option>
								<option>Northeast Catholic College</option>
								<option>
									Northeast Ohio Medical University
								</option>
								<option>
									Northeastern Illinois University
								</option>
								<option>Northeastern State University</option>
								<option>Northeastern University</option>
								<option>
									Northern Alberta Institute of Technology
								</option>
								<option>Northern Arizona University</option>
								<option>Northern Illinois University</option>
								<option>Northern Kentucky University</option>
								<option>Northern Michigan University</option>
								<option>Northern New Mexico College</option>
								<option>Northern State University</option>
								<option>Northern Vermont University</option>
								<option>Northern Vermont University</option>
								<option>Northland College</option>
								<option>Northpoint Bible College</option>
								<option>Northwest Christian University</option>
								<option>Northwest Florida State College</option>
								<option>
									Northwest Missouri State University
								</option>
								<option>Northwest Nazarene University</option>
								<option>Northwest University</option>
								<option>Northwestern College</option>
								<option>
									Northwestern Health Sciences University
								</option>
								<option>
									Northwestern Oklahoma State University
								</option>
								<option>
									Northwestern State University of Louisiana
								</option>
								<option>Northwestern University</option>
								<option>Northwood University</option>
								<option>Norwich University</option>
								<option>Notre Dame College</option>
								<option>Notre Dame de Namur University</option>
								<option>
									Notre Dame of Maryland University
								</option>
								<option>Nova Southeastern University</option>
								<option>Nyack College</option>
								<option>OCAD University</option>
								<option>Oak Hills Christian College</option>
								<option>Oakland City University</option>
								<option>Oakland University</option>
								<option>Oakwood University</option>
								<option>Oberlin College</option>
								<option>Occidental College</option>
								<option>Oglethorpe University</option>
								<option>Ohio Christian University</option>
								<option>Ohio Dominican University</option>
								<option>Ohio Northern University</option>
								<option>Ohio University</option>
								<option>Ohio University Southern</option>
								<option>Ohio Valley University</option>
								<option>Ohio Wesleyan University</option>
								<option>Oklahoma Baptist University</option>
								<option>Oklahoma Christian University</option>
								<option>Oklahoma City University</option>
								<option>
									Oklahoma Panhandle State University
								</option>
								<option>Oklahoma State University</option>
								<option>Oklahoma Wesleyan University</option>
								<option>Old Dominion University</option>
								<option>Olivet College</option>
								<option>Olivet Nazarene University</option>
								<option>Oral Roberts University</option>
								<option>Oregon College of Art and Craft</option>
								<option>
									Oregon Health &amp; Science University
								</option>
								<option>Oregon Institute of Technology</option>
								<option>Oregon State University</option>
								<option>Otis College of Art and Design</option>
								<option>Ottawa University</option>
								<option>Otterbein University</option>
								<option>Ouachita Baptist University</option>
								<option>Our Lady of the Lake University</option>
								<option>Ozark Christian College</option>
								<option>Pace University</option>
								<option>Pacific Lutheran University</option>
								<option>
									Pacific Northwest College of Art
								</option>
								<option>Pacific Oaks College</option>
								<option>Pacific Union College</option>
								<option>Pacific University</option>
								<option>Pacifica Graduate Institute</option>
								<option>Paine College</option>
								<option>Palm Beach Atlantic University</option>
								<option>Palm Beach State College</option>
								<option>Palo Alto University</option>
								<option>Park University</option>
								<option>Parker University</option>
								<option>Patten University</option>
								<option>Paul Quinn College</option>
								<option>Paul Smith's College</option>
								<option>Peirce College</option>
								<option>Penn State College of Medicine</option>
								<option>Penn State University</option>
								<option>
									Pennsylvania Academy of the Fine Arts
								</option>
								<option>
									Pennsylvania College of Art and Design
								</option>
								<option>
									Pennsylvania College of Health Sciences
								</option>
								<option>
									Pennsylvania College of Technology
								</option>
								<option>Pensacola State College</option>
								<option>Pepperdine University</option>
								<option>Peru State College</option>
								<option>Pfeiffer University</option>
								<option>Philander Smith College</option>
								<option>Phillips Graduate University</option>
								<option>Piedmont College</option>
								<option>
									Piedmont International University
								</option>
								<option>Pillar College</option>
								<option>Pine Manor College</option>
								<option>Pittsburg State University</option>
								<option>Pitzer College</option>
								<option>Plaza College</option>
								<option>Plymouth State University</option>
								<option>Point Loma Nazarene University</option>
								<option>Point Park University</option>
								<option>Point University</option>
								<option>Polk State College</option>
								<option>Pomona College</option>
								<option>
									Pontifical Faculty of the Immaculate
									Conception
								</option>
								<option>Portland State University</option>
								<option>Post University</option>
								<option>Prairie View A&amp;M University</option>
								<option>Pratt Institute</option>
								<option>Presbyterian College</option>
								<option>Prescott College</option>
								<option>Presentation College</option>
								<option>Princeton University</option>
								<option>Principia College</option>
								<option>Providence Christian College</option>
								<option>Providence College</option>
								<option>Purchase College</option>
								<option>Purdue University</option>
								<option>Purdue University Fort Wayne</option>
								<option>Purdue University Global</option>
								<option>Purdue University Northwest</option>
								<option>Queen's University</option>
								<option>Queens College</option>
								<option>Queens University of Charlotte</option>
								<option>Quest University Canada</option>
								<option>Quincy University</option>
								<option>Quinnipiac University</option>
								<option>Radford University</option>
								<option>Ramapo College of New Jersey</option>
								<option>Randolph College</option>
								<option>Randolph-Macon College</option>
								<option>Rasmussen College</option>
								<option>Redeemer University College</option>
								<option>Reed College</option>
								<option>Regent University</option>
								<option>Regis College</option>
								<option>Regis University</option>
								<option>Reinhardt University</option>
								<option>
									Relay Graduate School of Education
								</option>
								<option>
									Rensselaer Polytechnic Institute
								</option>
								<option>Research College of Nursing</option>
								<option>Resurrection University</option>
								<option>Rhode Island College</option>
								<option>Rhode Island School of Design</option>
								<option>Rhodes College</option>
								<option>Rice University</option>
								<option>Richmont Graduate University</option>
								<option>Rider University</option>
								<option>
									Ringling College of Art and Design
								</option>
								<option>Ripon College</option>
								<option>Rivier University</option>
								<option>Roanoke College</option>
								<option>Robert Morris University</option>
								<option>
									Robert Morris University Illinois
								</option>
								<option>Roberts Wesleyan College</option>
								<option>Rochester College</option>
								<option>
									Rochester Institute of Technology
								</option>
								<option>Rockford University</option>
								<option>Rockhurst University</option>
								<option>Rocky Mountain College</option>
								<option>
									Rocky Mountain College of Art and Design
								</option>
								<option>
									Rocky Mountain University of Health
									Professions
								</option>
								<option>Rocky Vista University</option>
								<option>Roger Williams University</option>
								<option>Rogers State University</option>
								<option>Rollins College</option>
								<option>Roosevelt University</option>
								<option>
									Rosalind Franklin University of Medicine and
									Science
								</option>
								<option>
									Rose-Hulman Institute of Technology
								</option>
								<option>
									Roseman University of Health Sciences
								</option>
								<option>Rosemont College</option>
								<option>Rowan University</option>
								<option>Royal Roads University</option>
								<option>Rush University</option>
								<option>Rust College</option>
								<option>Rutgers</option>
								<option>Ryerson University</option>
								<option>SAIT Polytechnic</option>
								<option>SIT Graduate Institute</option>
								<option>SUNY Canton</option>
								<option>SUNY Cobleskill</option>
								<option>SUNY College at Old Westbury</option>
								<option>SUNY College at Oneonta</option>
								<option>
									SUNY College of Environmental Science and
									Forestry
								</option>
								<option>SUNY College of Optometry</option>
								<option>SUNY Cortland</option>
								<option>SUNY Delhi</option>
								<option>SUNY Downstate Medical Center</option>
								<option>SUNY Empire State College</option>
								<option>SUNY Geneseo</option>
								<option>SUNY Maritime College</option>
								<option>SUNY Polytechnic Institute</option>
								<option>SUNY Upstate Medical University</option>
								<option>Sacred Heart University</option>
								<option>Saginaw Valley State University</option>
								<option>Saint Ambrose University</option>
								<option>Saint Anselm College</option>
								<option>
									Saint Anthony College of Nursing
								</option>
								<option>Saint Augustine College</option>
								<option>
									Saint Francis Medical Center College of
									Nursing
								</option>
								<option>Saint Francis University</option>
								<option>Saint Johns River State College</option>
								<option>Saint Joseph's College of Maine</option>
								<option>Saint Joseph's University</option>
								<option>Saint Leo University</option>
								<option>Saint Louis University</option>
								<option>
									Saint Luke's College of Health Sciences
								</option>
								<option>Saint Martin's University</option>
								<option>Saint Mary's College</option>
								<option>
									Saint Mary's College of California
								</option>
								<option>Saint Mary's University</option>
								<option>
									Saint Mary's University of Minnesota
								</option>
								<option>Saint Mary-of-the-Woods College</option>
								<option>Saint Michael's College</option>
								<option>Saint Peter's University</option>
								<option>Saint Vincent College</option>
								<option>Saint Xavier University</option>
								<option>Salem College</option>
								<option>Salem International University</option>
								<option>Salem State University</option>
								<option>Salisbury University</option>
								<option>Salus University</option>
								<option>Salve Regina University</option>
								<option>Sam Houston State University</option>
								<option>Samford University</option>
								<option>Samuel Merritt University</option>
								<option>San Diego Christian College</option>
								<option>San Diego State University</option>
								<option>San Francisco Art Institute</option>
								<option>
									San Francisco Conservatory of Music
								</option>
								<option>San Francisco State University</option>
								<option>San Joaquin College of Law</option>
								<option>San José State University</option>
								<option>Santa Clara University</option>
								<option>Santa Fe College</option>
								<option>
									Santa Fe University of Art and Design
								</option>
								<option>Sarah Lawrence College</option>
								<option>
									Savannah College of Art and Design
								</option>
								<option>Savannah State University</option>
								<option>Saybrook University</option>
								<option>
									Schiller International University
								</option>
								<option>School of Visual Arts</option>
								<option>
									School of the Art Institute of Chicago
								</option>
								<option>Schreiner University</option>
								<option>Scripps College</option>
								<option>Seattle Central College</option>
								<option>Seattle Pacific University</option>
								<option>Seattle University</option>
								<option>
									Seminole State College of Florida
								</option>
								<option>Seton Hall University</option>
								<option>Seton Hill University</option>
								<option>
									Sewanee: The University of the South
								</option>
								<option>Shaw University</option>
								<option>Shawnee State University</option>
								<option>Shenandoah University</option>
								<option>Shepherd University</option>
								<option>Shimer College</option>
								<option>
									Shippensburg University of Pennsylvania
								</option>
								<option>Shorter University</option>
								<option>Siena College</option>
								<option>Siena Heights University</option>
								<option>Sierra Nevada College</option>
								<option>
									Silver Lake College of the Holy Family
								</option>
								<option>Simmons University</option>
								<option>Simon Fraser University</option>
								<option>Simpson College</option>
								<option>Simpson University</option>
								<option>Skidmore College</option>
								<option>
									Slippery Rock University of Pennsylvania
								</option>
								<option>Smith College</option>
								<option>Snow College</option>
								<option>Sofia University</option>
								<option>Soka University of America</option>
								<option>Sonoma State University</option>
								<option>South Carolina State University</option>
								<option>South College</option>
								<option>
									South Dakota School of Mines and Technology
								</option>
								<option>South Dakota State University</option>
								<option>South Georgia State College</option>
								<option>South Texas College</option>
								<option>South Texas College of Law</option>
								<option>South University</option>
								<option>
									Southeast Missouri State University
								</option>
								<option>Southeastern Baptist College</option>
								<option>
									Southeastern Louisiana University
								</option>
								<option>
									Southeastern Oklahoma State University
								</option>
								<option>Southeastern University</option>
								<option>Southern Adventist University</option>
								<option>Southern Arkansas University</option>
								<option>
									Southern California Institute of
									Architecture
								</option>
								<option>Southern College of Optometry</option>
								<option>
									Southern Connecticut State University
								</option>
								<option>
									Southern Illinois University Carbondale
								</option>
								<option>
									Southern Illinois University Edwardsville
								</option>
								<option>Southern Methodist University</option>
								<option>Southern Nazarene University</option>
								<option>
									Southern New Hampshire University
								</option>
								<option>Southern Oregon University</option>
								<option>Southern University Law Center</option>
								<option>
									Southern University and A&amp;M College
								</option>
								<option>
									Southern University at New Orleans
								</option>
								<option>Southern Utah University</option>
								<option>Southern Vermont College</option>
								<option>Southern Virginia University</option>
								<option>Southern Wesleyan University</option>
								<option>Southwest Baptist University</option>
								<option>
									Southwest Minnesota State University
								</option>
								<option>
									Southwest University of Visual Arts
								</option>
								<option>
									Southwestern Adventist University
								</option>
								<option>
									Southwestern Assemblies of God University
								</option>
								<option>
									Southwestern Christian University
								</option>
								<option>Southwestern College</option>
								<option>Southwestern College</option>
								<option>Southwestern Law School</option>
								<option>
									Southwestern Oklahoma State University
								</option>
								<option>Southwestern University</option>
								<option>Spalding University</option>
								<option>Spelman College</option>
								<option>Spring Arbor University</option>
								<option>Spring Hill College</option>
								<option>Springfield College</option>
								<option>St Andrews University</option>
								<option>St John's College</option>
								<option>St Mary's University</option>
								<option>St Petersburg College</option>
								<option>St Thomas University</option>
								<option>St. Augustine's University</option>
								<option>St. Bonaventure University</option>
								<option>St. Catherine University</option>
								<option>St. Cloud State University</option>
								<option>St. Edward's University</option>
								<option>St. Francis College</option>
								<option>St. Francis Xavier University</option>
								<option>St. Gregory's University</option>
								<option>St. John Fisher College</option>
								<option>St. John's University</option>
								<option>St. Joseph's College</option>
								<option>St. Lawrence University</option>
								<option>St. Louis Christian College</option>
								<option>St. Louis College of Pharmacy</option>
								<option>St. Mary's College of Maryland</option>
								<option>St. Mary's University</option>
								<option>St. Norbert College</option>
								<option>St. Olaf College</option>
								<option>St. Stephen's University</option>
								<option>St. Thomas Aquinas College</option>
								<option>St. Thomas More College</option>
								<option>St. Thomas University</option>
								<option>Stanford University</option>
								<option>
									State College of Florida-Manatee-Sarasota
								</option>
								<option>
									State University of New York College at
									Plattsburgh
								</option>
								<option>
									State University of New York at Fredonia
								</option>
								<option>
									State University of New York at New Paltz
								</option>
								<option>
									State University of New York at Oswego
								</option>
								<option>
									Stephen F. Austin State University
								</option>
								<option>Stephens College</option>
								<option>Sterling College</option>
								<option>Sterling College</option>
								<option>Stetson University</option>
								<option>Stevens Institute of Technology</option>
								<option>Stevenson University</option>
								<option>Stillman College</option>
								<option>Stockton University</option>
								<option>Stonehill College</option>
								<option>Stony Brook University</option>
								<option>Strayer University</option>
								<option>Suffolk University</option>
								<option>Sul Ross State University</option>
								<option>Sullivan University</option>
								<option>Susquehanna University</option>
								<option>Swarthmore College</option>
								<option>Sweet Briar College</option>
								<option>Syracuse University</option>
								<option>Tabor College</option>
								<option>Talladega College</option>
								<option>Tarleton State University</option>
								<option>Taylor University</option>
								<option>Temple University</option>
								<option>Tennessee State University</option>
								<option>
									Tennessee Technological University
								</option>
								<option>Tennessee Wesleyan University</option>
								<option>
									Texas A&amp;M International University
								</option>
								<option>Texas A&amp;M University</option>
								<option>
									Texas A&amp;M University at Galveston
								</option>
								<option>
									Texas A&amp;M University-Commerce
								</option>
								<option>
									Texas A&amp;M University-Corpus Christi
								</option>
								<option>
									Texas A&amp;M University-Kingsville
								</option>
								<option>
									Texas A&amp;M University-Texarkana
								</option>
								<option>Texas Christian University</option>
								<option>Texas College</option>
								<option>Texas Lutheran University</option>
								<option>Texas Southern University</option>
								<option>Texas State University</option>
								<option>Texas Tech University</option>
								<option>
									Texas Tech University Health Sciences Center
								</option>
								<option>Texas Wesleyan University</option>
								<option>Texas Woman's University</option>
								<option>
									The American College of Financial Services
								</option>
								<option>The Art Institute of Pittsburgh</option>
								<option>The Baptist College of Florida</option>
								<option>
									The Catholic University of America
								</option>
								<option>
									The Chicago School of Professional
									Psychology
								</option>
								<option>The Citadel</option>
								<option>The City College of New York</option>
								<option>The College at Brockport</option>
								<option>The College of Idaho</option>
								<option>The College of New Jersey</option>
								<option>The College of New Rochelle</option>
								<option>The College of Saint Rose</option>
								<option>The College of St. Scholastica</option>
								<option>The College of Westchester</option>
								<option>The College of Wooster</option>
								<option>
									The Cooper Union for the Advancement of
									Science and Art
								</option>
								<option>
									The Culinary Institute of America
								</option>
								<option>The Evergreen State College</option>
								<option>The Graduate Center</option>
								<option>The Institute of World Politics</option>
								<option>The John Marshall Law School</option>
								<option>The Juilliard School</option>
								<option>The King's College</option>
								<option>The King's University</option>
								<option>The King’s University</option>
								<option>The Master's University</option>
								<option>
									The National Graduate School of Quality
									Management
								</option>
								<option>The New School</option>
								<option>The Ohio State University</option>
								<option>The Rockefeller University</option>
								<option>The Sage Colleges</option>
								<option>
									The State University of New York at Potsdam
								</option>
								<option>The University of Alabama</option>
								<option>
									The University of Alabama in Huntsville
								</option>
								<option>The University of Arizona</option>
								<option>
									The University of British Columbia
								</option>
								<option>The University of Findlay</option>
								<option>The University of Maine</option>
								<option>The University of Memphis</option>
								<option>The University of Montana</option>
								<option>
									The University of Montana Western
								</option>
								<option>The University of Oklahoma</option>
								<option>
									The University of Oklahoma Health Sciences
									Center
								</option>
								<option>The University of Tampa</option>
								<option>The University of Tennessee</option>
								<option>
									The University of Tennessee Health Science
									Center
								</option>
								<option>
									The University of Tennessee at Chattanooga
								</option>
								<option>
									The University of Tennessee at Martin
								</option>
								<option>
									The University of Texas Health Science
									Center at Houston
								</option>
								<option>
									The University of Texas Health Science
									Center at San Antonio
								</option>
								<option>
									The University of Texas Health Science
									Center at Tyler
								</option>
								<option>
									The University of Texas MD Anderson Cancer
									Center
								</option>
								<option>
									The University of Texas Medical Branch at
									Galveston
								</option>
								<option>
									The University of Texas at Arlington
								</option>
								<option>
									The University of Texas at Austin
								</option>
								<option>
									The University of Texas at Dallas
								</option>
								<option>
									The University of Texas at El Paso
								</option>
								<option>
									The University of Texas at San Antonio
								</option>
								<option>
									The University of Texas at Tyler
								</option>
								<option>
									The University of Texas of the Permian Basin
								</option>
								<option>The University of Tulsa</option>
								<option>The University of Utah</option>
								<option>
									The University of Virginia's College at Wise
								</option>
								<option>
									The University of West Los Angeles
								</option>
								<option>The University of Winnipeg</option>
								<option>The University of the Arts</option>
								<option>The Wright Institute</option>
								<option>Thiel College</option>
								<option>Thomas Aquinas College</option>
								<option>Thomas College</option>
								<option>Thomas Edison State University</option>
								<option>Thomas Jefferson School of Law</option>
								<option>Thomas Jefferson University</option>
								<option>Thomas More College</option>
								<option>
									Thomas More College of Liberal Arts
								</option>
								<option>Thomas University</option>
								<option>Thompson Rivers University</option>
								<option>Tiffin University</option>
								<option>Toccoa Falls College</option>
								<option>Tougaloo College</option>
								<option>Touro College</option>
								<option>Touro University California</option>
								<option>Towson University</option>
								<option>Transylvania University</option>
								<option>Trent University</option>
								<option>Trevecca Nazarene University</option>
								<option>Tri-State Bible College</option>
								<option>Trine University</option>
								<option>Trinity Bible College</option>
								<option>Trinity Christian College</option>
								<option>Trinity College</option>
								<option>Trinity College of Florida</option>
								<option>
									Trinity College of Nursing &amp; Health
									Sciences
								</option>
								<option>
									Trinity International University
								</option>
								<option>Trinity University</option>
								<option>Trinity Washington University</option>
								<option>Trinity Western University</option>
								<option>Trocaire College</option>
								<option>Troy University</option>
								<option>Truett McConnell University</option>
								<option>Truman State University</option>
								<option>Tufts University</option>
								<option>Tulane University</option>
								<option>Tusculum College</option>
								<option>Tuskegee University</option>
								<option>
									Uniformed Services University of the Health
									Sciences
								</option>
								<option>Union College</option>
								<option>Union College</option>
								<option>Union College</option>
								<option>
									Union Institute &amp; University
								</option>
								<option>Union University</option>
								<option>United States Air Force Academy</option>
								<option>
									United States Coast Guard Academy
								</option>
								<option>
									United States Merchant Marine Academy
								</option>
								<option>United States Military Academy</option>
								<option>United States Naval Academy</option>
								<option>United States Sports Academy</option>
								<option>United States University</option>
								<option>Unity College</option>
								<option>University Canada West</option>
								<option>University at Albany</option>
								<option>University at Buffalo</option>
								<option>
									University of Advancing Technology
								</option>
								<option>University of Akron</option>
								<option>
									University of Alabama at Birmingham
								</option>
								<option>University of Alaska Anchorage</option>
								<option>University of Alaska Fairbanks</option>
								<option>University of Alaska Southeast</option>
								<option>University of Alberta</option>
								<option>University of Arkansas</option>
								<option>
									University of Arkansas - Fort Smith
								</option>
								<option>
									University of Arkansas at Little Rock
								</option>
								<option>
									University of Arkansas at Monticello
								</option>
								<option>
									University of Arkansas at Pine Bluff
								</option>
								<option>
									University of Arkansas for Medical Sciences
								</option>
								<option>University of Baltimore</option>
								<option>University of Bridgeport</option>
								<option>University of Calgary</option>
								<option>
									University of California, Berkley
								</option>
								<option>University of California, Davis</option>
								<option>
									University of California, Hastings
								</option>
								<option>
									University of California, Irvine
								</option>
								<option>
									University of California, Los Angeles
								</option>
								<option>
									University of California, Merced
								</option>
								<option>
									University of California, Riverside
								</option>
								<option>
									University of California, San Diego
								</option>
								<option>
									University of California, San Francisco
								</option>
								<option>
									University of California, Santa Barbara
								</option>
								<option>
									University of California, Santa Cruz
								</option>
								<option>University of Central Arkansas</option>
								<option>University of Central Florida</option>
								<option>University of Central Missouri</option>
								<option>University of Central Oklahoma</option>
								<option>University of Charleston</option>
								<option>University of Chicago</option>
								<option>University of Cincinnati</option>
								<option>University of Colorado Boulder</option>
								<option>
									University of Colorado Colorado Springs
								</option>
								<option>University of Colorado Denver</option>
								<option>University of Connecticut</option>
								<option>University of Dallas</option>
								<option>University of Dayton</option>
								<option>University of Delaware</option>
								<option>University of Denver</option>
								<option>University of Detroit Mercy</option>
								<option>University of Dubuque</option>
								<option>University of Evansville</option>
								<option>University of Florida</option>
								<option>University of Georgia</option>
								<option>University of Guelph</option>
								<option>University of Hartford</option>
								<option>University of Hawaii at Hilo</option>
								<option>University of Hawaii at Manoa</option>
								<option>University of Hawaii-West Oahu</option>
								<option>University of Holy Cross</option>
								<option>University of Houston</option>
								<option>
									University of Houston-Clear Lake
								</option>
								<option>University of Houston-Downtown</option>
								<option>University of Houston-Victoria</option>
								<option>University of Idaho</option>
								<option>
									University of Illinois at Chicago
								</option>
								<option>
									University of Illinois at Springfield
								</option>
								<option>
									University of Illinois at Urbana-Champaign
								</option>
								<option>University of Indianapolis</option>
								<option>University of Iowa</option>
								<option>University of Jamestown</option>
								<option>University of Kansas</option>
								<option>University of Kentucky</option>
								<option>University of King's College</option>
								<option>University of La Verne</option>
								<option>University of Lethbridge</option>
								<option>
									University of Louisiana at Lafayette
								</option>
								<option>
									University of Louisiana at Monroe
								</option>
								<option>University of Louisville</option>
								<option>University of Maine at Augusta</option>
								<option>
									University of Maine at Farmington
								</option>
								<option>
									University of Maine at Fort Kent
								</option>
								<option>University of Maine at Machias</option>
								<option>
									University of Maine at Presque Isle
								</option>
								<option>University of Manitoba</option>
								<option>University of Mary</option>
								<option>
									University of Mary Hardin-Baylor
								</option>
								<option>University of Mary Washington</option>
								<option>University of Maryland</option>
								<option>University of Maryland</option>
								<option>University of Maryland</option>
								<option>
									University of Maryland Eastern Shore
								</option>
								<option>
									University of Maryland University College
								</option>
								<option>
									University of Massachusetts Amherst
								</option>
								<option>
									University of Massachusetts Boston
								</option>
								<option>
									University of Massachusetts Dartmouth
								</option>
								<option>
									University of Massachusetts Lowell
								</option>
								<option>
									University of Massachusetts Medical School
								</option>
								<option>University of Miami</option>
								<option>University of Michigan</option>
								<option>University of Michigan-Dearborn</option>
								<option>University of Michigan-Flint</option>
								<option>University of Minnesota</option>
								<option>University of Mississippi</option>
								<option>
									University of Mississippi Medical Center
								</option>
								<option>University of Missouri</option>
								<option>
									University of Missouri-Kansas City
								</option>
								<option>
									University of Missouri-St. Louis
								</option>
								<option>University of Mobile</option>
								<option>University of Montevallo</option>
								<option>University of Mount Olive</option>
								<option>University of Mount Union</option>
								<option>
									University of Nebraska Medical Center
								</option>
								<option>
									University of Nebraska at Kearney
								</option>
								<option>University of Nebraska at Omaha</option>
								<option>University of Nebraska-Lincoln</option>
								<option>University of Nevada</option>
								<option>University of Nevada</option>
								<option>University of New Brunswick</option>
								<option>University of New England</option>
								<option>University of New Hampshire</option>
								<option>University of New Haven</option>
								<option>University of New Mexico</option>
								<option>University of New Orleans</option>
								<option>University of North Alabama</option>
								<option>
									University of North Carolina School of the
									Arts
								</option>
								<option>
									University of North Carolina Wilmington
								</option>
								<option>
									University of North Carolina at Asheville
								</option>
								<option>
									University of North Carolina at Chapel Hill
								</option>
								<option>
									University of North Carolina at Charlotte
								</option>
								<option>
									University of North Carolina at Greensboro
								</option>
								<option>
									University of North Carolina at Pembroke
								</option>
								<option>University of North Dakota</option>
								<option>University of North Florida</option>
								<option>University of North Georgia</option>
								<option>University of North Texas</option>
								<option>
									University of North Texas Health Science
									Center
								</option>
								<option>
									University of Northern British Columbia
								</option>
								<option>University of Northern Colorado</option>
								<option>University of Northern Iowa</option>
								<option>
									University of Northwestern - St. Paul
								</option>
								<option>University of Northwestern Ohio</option>
								<option>University of Notre Dame</option>
								<option>
									University of Ontario Institute of
									Technology
								</option>
								<option>University of Oregon</option>
								<option>University of Ottawa</option>
								<option>University of Pennsylvania</option>
								<option>University of Pikeville</option>
								<option>University of Pittsburgh</option>
								<option>University of Portland</option>
								<option>
									University of Prince Edward Island
								</option>
								<option>University of Providence</option>
								<option>University of Puget Sound</option>
								<option>University of Redlands</option>
								<option>University of Regina</option>
								<option>University of Rhode Island</option>
								<option>University of Richmond</option>
								<option>University of Rio Grande</option>
								<option>University of Rochester</option>
								<option>University of Saint Francis</option>
								<option>University of Saint Joseph</option>
								<option>University of Saint Mary</option>
								<option>University of San Diego</option>
								<option>University of San Francisco</option>
								<option>University of Saskatchewan</option>
								<option>
									University of Science and Arts of Oklahoma
								</option>
								<option>University of Scranton</option>
								<option>University of Sioux Falls</option>
								<option>University of South Alabama</option>
								<option>University of South Carolina</option>
								<option>
									University of South Carolina-Aiken
								</option>
								<option>
									University of South Carolina-Beaufort
								</option>
								<option>
									University of South Carolina-Upstate
								</option>
								<option>University of South Dakota</option>
								<option>University of South Florida</option>
								<option>
									University of Southern California
								</option>
								<option>University of Southern Indiana</option>
								<option>University of Southern Maine</option>
								<option>
									University of Southern Mississippi
								</option>
								<option>University of St Thomas</option>
								<option>
									University of St. Augustine for Health
									Sciences
								</option>
								<option>University of St. Francis</option>
								<option>University of St. Thomas</option>
								<option>
									University of Texas Rio Grande Valley
								</option>
								<option>
									University of Texas Southwestern Medical
									Center
								</option>
								<option>University of Toledo</option>
								<option>University of Toronto</option>
								<option>University of Valley Forge</option>
								<option>University of Vermont</option>
								<option>University of Victoria</option>
								<option>University of Virginia</option>
								<option>University of Washington</option>
								<option>University of Waterloo</option>
								<option>University of West Alabama</option>
								<option>University of West Florida</option>
								<option>University of West Georgia</option>
								<option>University of Western States</option>
								<option>University of Windsor</option>
								<option>
									University of Wisconsin-Eau Claire
								</option>
								<option>
									University of Wisconsin-Green Bay
								</option>
								<option>
									University of Wisconsin-La Crosse
								</option>
								<option>University of Wisconsin-Madison</option>
								<option>
									University of Wisconsin-Milwaukee
								</option>
								<option>University of Wisconsin-Oshkosh</option>
								<option>
									University of Wisconsin-Parkside
								</option>
								<option>
									University of Wisconsin-Platteville
								</option>
								<option>
									University of Wisconsin-River Falls
								</option>
								<option>
									University of Wisconsin-Stevens Point
								</option>
								<option>University of Wisconsin-Stout</option>
								<option>
									University of Wisconsin-Superior
								</option>
								<option>
									University of Wisconsin-Whitewater
								</option>
								<option>University of Wyoming</option>
								<option>University of the Cumberlands</option>
								<option>
									University of the District of Columbia
								</option>
								<option>University of the Fraser Valley</option>
								<option>
									University of the Incarnate Word
								</option>
								<option>University of the Ozarks</option>
								<option>University of the Pacific</option>
								<option>University of the Potomac</option>
								<option>University of the Rockies</option>
								<option>
									University of the Sciences in Philadelphia
								</option>
								<option>University of the Southwest</option>
								<option>University of the West</option>
								<option>Université Laval</option>
								<option>Université Sainte-Anne</option>
								<option>Université de Hearst</option>
								<option>Université de Moncton</option>
								<option>Université de Montréal</option>
								<option>Université de Saint-Boniface</option>
								<option>Université de Sherbrooke</option>
								<option>Université du Québec</option>
								<option>
									Université du Québec en
									Abitibi-Témiscamingue
								</option>
								<option>
									Université du Québec en Outaouais
								</option>
								<option>
									Université du Québec à Chicoutimi
								</option>
								<option>Université du Québec à Montréal</option>
								<option>Université du Québec à Rimouski</option>
								<option>
									Université du Québec à Trois-Rivières
								</option>
								<option>Upper Iowa University</option>
								<option>Urbana University</option>
								<option>Ursinus College</option>
								<option>Ursuline College</option>
								<option>Utah State University</option>
								<option>Utah Valley University</option>
								<option>Utica College</option>
								<option>Valdosta State University</option>
								<option>Valencia College</option>
								<option>Valley City State University</option>
								<option>Valparaiso University</option>
								<option>Vancouver Island University</option>
								<option>VanderCook College of Music</option>
								<option>Vanderbilt University</option>
								<option>
									Vanguard University of Southern California
								</option>
								<option>Vassar College</option>
								<option>
									Vaughn College of Aeronautics and Technology
								</option>
								<option>Vermont College of Fine Arts</option>
								<option>Vermont Law School</option>
								<option>Vermont Technical College</option>
								<option>Villa Maria College</option>
								<option>Villanova University</option>
								<option>Vincennes University</option>
								<option>
									Virginia Commonwealth University
								</option>
								<option>Virginia Military Institute</option>
								<option>
									Virginia Polytechnic Institute and State
									University
								</option>
								<option>Virginia State University</option>
								<option>Virginia Union University</option>
								<option>Virginia Wesleyan University</option>
								<option>Viterbo University</option>
								<option>Voorhees College</option>
								<option>
									WON Institute of Graduate Studies
								</option>
								<option>Wabash College</option>
								<option>Wade College</option>
								<option>Wagner College</option>
								<option>Wake Forest University</option>
								<option>Waldorf University</option>
								<option>Walla Walla University</option>
								<option>Walsh College</option>
								<option>Walsh University</option>
								<option>Warner Pacific College</option>
								<option>Warner University</option>
								<option>Warren Wilson College</option>
								<option>Wartburg College</option>
								<option>Washburn University</option>
								<option>
									Washington &amp; Jefferson College
								</option>
								<option>Washington Adventist University</option>
								<option>Washington College</option>
								<option>Washington State University</option>
								<option>
									Washington University in St. Louis
								</option>
								<option>Washington and Lee University</option>
								<option>Watkins College of Art</option>
								<option>Wayland Baptist University</option>
								<option>Wayne State College</option>
								<option>Wayne State University</option>
								<option>Waynesburg University</option>
								<option>Webb Institute</option>
								<option>Webber International University</option>
								<option>Weber State University</option>
								<option>Webster University</option>
								<option>Welch College</option>
								<option>Wellesley College</option>
								<option>Wells College</option>
								<option>
									Wentworth Institute of Technology
								</option>
								<option>Wesley College</option>
								<option>Wesleyan College</option>
								<option>Wesleyan University</option>
								<option>
									West Chester University of Pennsylvania
								</option>
								<option>
									West Coast University-Los Angeles
								</option>
								<option>West Liberty University</option>
								<option>West Texas A&amp;M University</option>
								<option>
									West Virginia School of Osteopathic Medicine
								</option>
								<option>West Virginia State University</option>
								<option>West Virginia University</option>
								<option>
									West Virginia University Institute of
									Technology
								</option>
								<option>
									West Virginia University at Parkersburg
								</option>
								<option>West Virginia Wesleyan College</option>
								<option>Western Carolina University</option>
								<option>
									Western Connecticut State University
								</option>
								<option>Western Illinois University</option>
								<option>Western Kentucky University</option>
								<option>Western Michigan University</option>
								<option>Western Nevada College</option>
								<option>Western New England University</option>
								<option>Western New Mexico University</option>
								<option>Western Oregon University</option>
								<option>
									Western State College of Law at Argosy
									University
								</option>
								<option>
									Western State Colorado University
								</option>
								<option>Western University</option>
								<option>
									Western University of Health Sciences
								</option>
								<option>Western Washington University</option>
								<option>Westfield State University</option>
								<option>Westminster College</option>
								<option>Westminster College</option>
								<option>Westminster College</option>
								<option>Westmont College</option>
								<option>Wheaton College</option>
								<option>Wheaton College</option>
								<option>Wheeling Jesuit University</option>
								<option>Whitman College</option>
								<option>Whittier College</option>
								<option>Whitworth University</option>
								<option>Wichita State University</option>
								<option>Widener University</option>
								<option>Wilberforce University</option>
								<option>Wiley College</option>
								<option>Wilfrid Laurier University</option>
								<option>Wilkes University</option>
								<option>Willamette University</option>
								<option>William Carey University</option>
								<option>William James College</option>
								<option>William Jessup University</option>
								<option>William Jewell College</option>
								<option>
									William Paterson University of New Jersey
								</option>
								<option>William Peace University</option>
								<option>William Penn University</option>
								<option>William Woods University</option>
								<option>Williams Baptist College</option>
								<option>Williams College</option>
								<option>Wilmington College</option>
								<option>Wilmington University</option>
								<option>Wilson College</option>
								<option>Wingate University</option>
								<option>Winona State University</option>
								<option>Winston-Salem State University</option>
								<option>Winthrop University</option>
								<option>Wisconsin Lutheran College</option>
								<option>
									Wisconsin School of Professional Psychology
								</option>
								<option>Wittenberg University</option>
								<option>Wofford College</option>
								<option>Woodbury University</option>
								<option>Worcester Polytechnic Institute</option>
								<option>Worcester State University</option>
								<option>Wright State University</option>
								<option>Xavier University</option>
								<option>Xavier University of Louisiana</option>
								<option>Yale University</option>
								<option>Yeshiva University</option>
								<option>York College</option>
								<option>York College</option>
								<option>York College of Pennsylvania</option>
								<option>York University</option>
								<option>Yorkville University</option>
								<option>Young Harris College</option>
								<option>Youngstown State University</option>
								<option>
									École Nationale d'Administration Publique
								</option>
								<option>École Polytechnique de Montréal</option>
								<option>
									École de Technologie Supérieure
								</option>{" "}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="formStudentName">
							<Form.Label>{intl.formatMessage({id: 'send-money-tuition.form.student-name.label'})}</Form.Label>
							<Form.Control
								as="input"
								type="text"
								name="name"
								required
								placeholder="Enter your name"
								className={styles.sendMoneyTuitionInput}
								onChange={event => this.onNameChange(event)}
							></Form.Control>
						</Form.Group>
						<Button className={styles.tryItNowButton} type="submit">
						{intl.formatMessage({id: 'send-money-tuition.form.submit-button.text'})}
						</Button>
					</Form>
				</div>
			</Col>
		);
	}
}

export default injectIntl(SendMoneyTuition);
